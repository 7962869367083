import React from "react";
import WritingTemplate from "../../components/writing/template";
import Card from "../../components/writing/template/card";

import story from "../../images/writing/storyheader.png";


export default function () {
  return (
    <WritingTemplate
      title="Do you like your story?"
      subtitle="No, I’m not a fan of mine."
      imageLink={story}
    >
      <Card>
        <p>
          Everyone loves a good story but not everyone likes theirs. I don’t
          like mine frankly. Do you? Some pages are marked with blood; pieces of
          me that I was hesitant to share. Lines that I have tried to cross out
          instead stand and taunt me. Some pages are on the edge because I keep
          going back to them; for clarity, for hope, for happiness. We all have
          unwanted pages. Don’t you?
        </p>
        <p>
          A brother passed. <br />
          A bullet was fired. <br />
          A friend betrayed.
          <br /> A mother left.
          <br /> A steering wheel jerked.
          <br /> A car lost control.
          <br /> A girl didn’t believe enough.
        </p>
        <p>
          I have a few empty pages left. I am the architect now. Choosing my
          characters, the emotions I can share, the stories I can tell, and how
          vulnerable I can get. Tinted in blue are my favourite stories. About
          the times I laughed so hard I started to cry, the feeling of working
          so hard and watching everything align, the moments I shared parts of
          me only my mirror had ever seen.
        </p>
        <p>
          I look forward to crafting my beautiful story. I look forward to
          choosing my characters and their distinctive roles. I look forward to
          shedding my thick skin, on my terms, and at my pace. I look forward to
          experiencing life.
        </p>
      </Card>
    </WritingTemplate>
  );
}
